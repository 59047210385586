<template>
  <navbar />
  <div class="main">
    <div class="max-w-7xl mx-auto">
      <slot />

      <Modal v-if="modalContext.isOpen" @close="modalContext.isOpen = false" ref="modalRef">
        <modalContext.content />
      </Modal>
    </div>
  </div>
</template>

<script setup lang="ts">
import { provide } from 'vue';
import useModal from "@/composables/useModal"
import useData from "@/composables/useData"

const route = useRoute();


const {
  site,
} = await useData()

const debug = computed(() => !!route.query.debughivancouver)
provide('debug', debug);
onMounted(() => {
  window._debug = debug.value

  watch(debug, (_n, _o) => {
    window._debug = _n
  })
})

useHead({
  link: [
    computed(() => (
      {
        rel: 'icon',
        type: 'image/x-icon',
        href: site.value?.favicon
      })),
  ],
  title: computed(() => site.value?.siteTitle || undefined),
  meta: [
    computed(() => ({
      name: 'description',
      content: site.value?.siteDescription
    }))
  ],
  script: [
    { src: "https://identity.netlify.com/v1/netlify-identity-widget.js" },
  ],
})

const { modalContext, modalRef } = useModal();

</script>

<style>
body {
  background-color: #eee;
}

</style>
